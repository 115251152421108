import React  from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Badge.module.scss";

/**
 * @type BadgeProps
 */
export type BadgeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * @const Badge
 */
const Badge = (props: BadgeProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <div { ...restProps } className={ clsx(classNames.badge, className) } />
    );
};

export default Badge;
