import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import {Link, navigate} from "gatsby"

import clsx from "clsx";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Heading from "@js/component/Heading";
import UserEditIcon from "@js/component/icon/UserEditIcon";
import BoxesIcon from "@js/component/icon/BoxesIcon";
import SignOutIcon from "@js/component/icon/SignOutIcon";
import TagIcon from "@js/component/icon/TagIcon";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import Badge from "@js/component/Badge";

import StoreState from "@js/store/StoreState";

import {setHasPartnerships, setOpen} from "@js/actionCreators/statusActionCreators";
import { setProfileComplete } from "@js/actionCreators/statusActionCreators";

import * as classNames from "@css/component/DashboardView.module.scss";
import {setSecurity} from "@js/actionCreators/loginActionCreators";

/**
 * @type DashboardViewProps
 */
export type DashboardViewProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;
/**
 * @const DashboardView
 */
const DashboardView = (props: DashboardViewProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const open = useSelector((storeState: StoreState) => storeState.status.open);
    const profileComplete = useSelector((storeState: StoreState) => storeState.status.profileComplete);
    const hasPartnerships = useSelector((storeState: StoreState) => storeState.status.hasPartnerships);

    const firstName = useSelector((storeState: StoreState) => storeState.security.firstName);
    const lastName = useSelector((storeState: StoreState) => storeState.security.lastName);
    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getStatus = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/myData/status?token=${ token }`, {
            method: "GET"
        });

        const data = await response.json();

        if(data.forceLogout !== undefined && data.forceLogout) {
            dispatch(setSecurity("", "", "", "", ""));
            navigate("/portaal/login");
        }

        dispatch(setOpen(data.open));
        dispatch(setProfileComplete(data.profileComplete));
        dispatch(setHasPartnerships(data.hasPartnerships));
    };

    useEffect(() => {
        getStatus();
    }, []);

    return (
        <div { ...restProps } className={ clsx(classNames.dashboardView, className) }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="medium">
                    Welkom, { firstName } { lastName }
                </Heading>
                <p className={ classNames.paragraph }>
                    Je bevindt je op de hoofdpagina van het dashboard voor jouw bedrijf.
                    Hier vind je het hoofdmenu en linksboven een snelmenu, welke je vanuit een ieder scherm in je dashboard kan gebruiken.
                    In het (snel)menu zie je de afzonderlijke menupagina’s waar je naartoe kan navigeren.
                    Hier worden ook notificaties weergegeven indien pagina’s jouw aandacht vereisen.
                    De eerste menupagina leidt je naar ‘Mijn aanvragen’, waar je al jouw opdrachten en de details hiervan kan bekijken en beheren.
                    Om jouw unieke bedrijfsprofiel in de vergelijker te tonen en opdrachtbevestigingen te genereren hebben we enkele gegevens van je nodig.
                    Deze kan je invullen en altijd wijzigen op de pagina ‘Mijn gegevens’.
                    Wanneer je alle gegevens op de pagina ‘Mijn gegevens’ hebt ingevuld wordt de pagina ‘Mijn producten’ opengesteld,
                    waarin je aangeeft welke producten je onder welke condities wenst aan te bieden.
                    Geef daar per afzonderlijke product aan in welk werkgebied je actief bent,
                    wat je huidige levertijd en beschikbaarheid is en voor welke tarieven je kan leveren.
                </p>
                <div className={ classNames.blocks }>
                    <div className={ clsx(classNames.block, { [classNames.disabled]: !(profileComplete) }) }>
                        <Link className={ classNames.link } to="/portaal/mijn-aanvragen">
                            <TagIcon className={ classNames.icon } />
                            <span className={ classNames.label }>Mijn aanvragen</span>
                            <ChevronRightIcon className={ classNames.iconOnHover } />
                            { open > 0 && <Badge className={ classNames.badge }>{ open }</Badge> }
                        </Link>
                    </div>
                    <div className={ clsx(classNames.block, { [classNames.profileNotComplete]: !(profileComplete) }) }>
                        <Link className={ classNames.link } to="/portaal/mijn-gegevens">
                            <UserEditIcon className={ classNames.icon } />
                            <span className={ classNames.label }>Mijn gegevens</span>
                            <ChevronRightIcon className={ classNames.iconOnHover } />
                        </Link>
                    </div>
                    <div className={ clsx(classNames.block, { [classNames.disabled]: !(profileComplete) }) }>
                        <Link className={ classNames.link } to="/portaal/mijn-producten">
                            <BoxesIcon className={ classNames.icon } />
                            <span className={ classNames.label }>Mijn producten</span>
                            <ChevronRightIcon className={ classNames.iconOnHover } />
                        </Link>
                    </div>
                    <div className={ classNames.block }>
                        <Link className={ classNames.link } to="/portaal/afmelden">
                            <SignOutIcon className={ classNames.icon } />
                            <span className={ classNames.label }>Afmelden</span>
                            <ChevronRightIcon className={ classNames.iconOnHover } />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardView;
