// extracted by mini-css-extract-plugin
export var blockOne = "Dashboard-module--blockOne--ya6Zs";
export var blockTwo = "Dashboard-module--blockTwo--IxzkN";
export var blocks = "Dashboard-module--blocks--TigDt";
export var box = "Dashboard-module--box--1+5rJ";
export var breadcrumbs = "Dashboard-module--breadcrumbs--GsUwo";
export var container = "Dashboard-module--container--gcL4X";
export var current = "Dashboard-module--current--FGaqv";
export var dashboardPage = "Dashboard-module--dashboardPage--+YLri";
export var header = "Dashboard-module--header--EVaCZ";
export var heading = "Dashboard-module--heading--PsZLF";
export var main = "Dashboard-module--main--b0oFs";