// extracted by mini-css-extract-plugin
export var badge = "DashboardView-module--badge--D9qJi";
export var block = "DashboardView-module--block--b7ZTH";
export var blocks = "DashboardView-module--blocks--Uscta";
export var container = "DashboardView-module--container--wiMBi";
export var dashboardView = "DashboardView-module--dashboardView--UiucJ";
export var disabled = "DashboardView-module--disabled--lBCSO";
export var heading = "DashboardView-module--heading--LLGXk";
export var icon = "DashboardView-module--icon--ASsCX";
export var iconOnHover = "DashboardView-module--iconOnHover--ejSLw";
export var label = "DashboardView-module--label--yKhBO";
export var link = "DashboardView-module--link--kohJz";
export var paragraph = "DashboardView-module--paragraph--LfbTI";
export var profileNotComplete = "DashboardView-module--profileNotComplete--DZ9IG";