import {SetHasPartnershipsAction, SetOpenAction, SetProfileCompleteAction} from "@js/actions/statusActions";
import {SET_HAS_PARTNERSHIPS, SET_OPEN, SET_PROFILE_COMPLETE} from "@js/actionTypes/statusActionTypes";

/**
 * @const setOpen
 */
export const setOpen = (open: number): SetOpenAction => ({
    payload: {
        open: open
    },
    type: SET_OPEN
});

/**
 * @const setProfileComplete
 */
export const setProfileComplete = (profileComplete: boolean): SetProfileCompleteAction => ({
    payload: {
        profileComplete: profileComplete
    },
    type: SET_PROFILE_COMPLETE
});

/**
 * @const setHasPartnerships
 */
export const setHasPartnerships = (hasPartnerships: boolean): SetHasPartnershipsAction => ({
    payload: {
        hasPartnerships: hasPartnerships
    },
    type: SET_HAS_PARTNERSHIPS
});
