import React from "react";

import { DetailedHTMLProps } from "react";
import { FormHTMLAttributes } from "react";
import {Link, navigate} from "gatsby";

import clsx from "clsx";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import BoxesIcon from "@js/component/icon/BoxesIcon";
import Badge from "@js/component/Badge";
import SignOutIcon from "@js/component/icon/SignOutIcon";
import TagIcon from "@js/component/icon/TagIcon";
import ThListIcon from "@js/component/icon/ThListIcon";
import UserEditIcon from "@js/component/icon/UserEditIcon";

import StoreState from "@js/store/StoreState";

import {setHasPartnerships, setOpen} from "@js/actionCreators/statusActionCreators";
import { setProfileComplete } from "@js/actionCreators/statusActionCreators";

import * as classNames from "@css/component/AdvisorMenu.module.scss";
import {setSecurity} from "@js/actionCreators/loginActionCreators";

/**
 * @type AdvisorMenuProps
 */
export type AdvisorMenuProps = Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const AdvisorMenu
 */
const AdvisorMenu = (props: AdvisorMenuProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const open = useSelector((storeState: StoreState) => storeState.status.open);
    const profileComplete = useSelector((storeState: StoreState) => storeState.status.profileComplete);
    const hasPartnerships = useSelector((storeState: StoreState) => storeState.status.hasPartnerships);

    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getStatus = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/myData/status?token=${ token }`, {
            method: "GET"
        });

        const data = await response.json();

        if(data.forceLogout !== undefined && data.forceLogout) {
            dispatch(setSecurity("", "", "", "", ""));
            navigate("/portaal/login");
        }

        dispatch(setOpen(data.open));
        dispatch(setProfileComplete(data.profileComplete));
        dispatch(setHasPartnerships(data.hasPartnerships));
    };

    useEffect(() => {
        getStatus();
    }, []);

    return (
        <div { ...restProps } className={ clsx(classNames.advisorMenu, className) } >
            <ul className={ classNames.list }>
                <li className={ classNames.listItem }>
                    <Link
                        activeClassName={ classNames.linkActive }
                        className={ classNames.link }
                        to="/portaal/dashboard"
                    >
                        <ThListIcon className={ classNames.icon } />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className={ classNames.listItem }>
                    <Link
                        activeClassName={ classNames.linkActive }
                        className={ clsx(classNames.link, { [classNames.disabled]: !(profileComplete) }) }
                        to="/portaal/mijn-aanvragen"
                    >
                        <TagIcon className={ classNames.icon } />
                        <span>Mijn aanvragen</span>
                        { open > 0 && <Badge className={ classNames.badge }>{ open }</Badge> }
                    </Link>
                </li>
                <li className={ classNames.listItem }>
                    <Link
                        activeClassName={ classNames.linkActive }
                        className={ classNames.link }
                        to="/portaal/mijn-gegevens"
                    >
                        <UserEditIcon className={ classNames.icon } />
                        <span>Mijn gegevens</span>
                        { !(profileComplete) &&
                            <Badge
                                className={ clsx(classNames.badge, classNames.orange) }
                                title="Er ontbreken nog enkele gegevens, ga naar 'Mijn gegevens' om je profiel te voltooien"
                            >!</Badge>
                        }
                    </Link>
                </li>
                <li className={ classNames.listItem }>
                    <Link
                        activeClassName={ classNames.linkActive }
                        className={ clsx(classNames.link, { [classNames.disabled]: !(profileComplete) }) }
                        to="/portaal/mijn-producten"
                    >
                        <BoxesIcon className={ classNames.icon } />
                        <span>Mijn producten</span>
                    </Link>
                </li>
                <li className={ classNames.listItem }>
                    <Link
                        activeClassName={ classNames.linkActive }
                        className={ classNames.link }
                        to="/portaal/afmelden"
                    >
                        <SignOutIcon className={ classNames.icon } />
                        <span>Afmelden</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default AdvisorMenu;
