import React from "react";

import { DetailedHTMLProps } from "react";
import { FormHTMLAttributes } from "react";
import { Link } from "gatsby"

import clsx from "clsx";

import { useSelector } from "react-redux";

import UserFriendsIcon from "@js/component/icon/UserFriendsIcon";

import StoreState from "@js/store/StoreState";

import * as classNames from "@css/component/AdvisorTopBar.module.scss";

/**
 * @type AdvisorTopBarProps
 */
export type AdvisorTopBarProps = Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const AdvisorTopBar
 */
const AdvisorTopBar = (props: AdvisorTopBarProps) => {
    const {
        className,
        ...restProps
    } = props;

    const username = useSelector((storeState: StoreState) => storeState.security.username);

    return (
        <div { ...restProps } className={ clsx(classNames.advisorTopBar, className) } >
            <div className={ classNames.container }>
                <ul className={ classNames.list }>
                    <li className={ classNames.listItem }>
                        <div className={ classNames.loggedInAs }>
                            <UserFriendsIcon className={ classNames.icon } />
                            <span className={ classNames.text }>Ingelogd als: </span>
                            <span className={ classNames.user }>{ username }</span>
                        </div>
                    </li>
                    <li className={ classNames.listItem }>
                        <Link
                            activeClassName={ classNames.linkActive }
                            className={ classNames.link }
                            to="/portaal/dashboard"
                        >
                            Naar dashboard
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AdvisorTopBar;
